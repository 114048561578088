@import 'Global';
//test2
.cfr {
    .header {
        background-color: #0068b3;
        margin-bottom: 2.5rem;
        padding: 1rem;
        img {
            height: 56px;
    margin-top: -10px;
    @include media("<tablet") {
        height: 38px;
        margin-top: 0;
    }
        }
    }
}