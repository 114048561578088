@import './Global';

$blue : #f1f1f1;

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
}
#root {
  clear: both;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin-bottom: -250px;
}
body {
  overflow: scroll !important;
  background-color: $blue;
  color: #333;
  margin-top: 0.2rem;
  &.modal-open {
    overflow: hidden !important;
  }

}


.header {
  .header-body {
    box-sizing: border-box;
    padding-top: 0.5rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include media("<=desktop") {
      padding:  0;
    }
    .app-title {
      position: absolute;
    font-weight: 500;
    top: 1.2rem;
    margin-left: 1rem;
    color: #095ba0;
    font-size: 1.3rem;
      @include media("<tablet") {
        opacity: 0;
      }
    }
    .app-subtitle {
      position: absolute;
    font-weight: 300;
    top: 2.5rem;
    margin-left: 1rem;
    color: #095ba0;
    font-size: 1.7rem;
      @include media("<tablet") {
        opacity: 0;
      }
    }
    .header-buttons {
      position: absolute;
      right: 1rem;
      top: 26px;
      @include media("<=desktop") {
        right: 0;
      }
      a {
        display: inline-block;
    border: 1px solid #095ba0;
    color: #095ba0;
    border-radius: 0.5rem;
    padding: 0.7rem 1.3rem;
    margin-left: 1rem;
    text-decoration: none;
      @include media("<tablet") {
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
      }
      }
    }
  }
}

.form-wrapper {
  
  background-color: #fff;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 2rem;
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom : 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 75px -61px #000;
    @include media("<=desktop") {
      margin-top: 0.5rem;
    }
    @include media("<=desktop") {
      width:95%;
    }
    @include media("<tablet") {
      padding: 1rem;
    }
  .step-wrapper {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.agree-section {
  margin-top: 4rem;
}
.agreement {
  position: relative;
        margin: 1rem 0;
        padding: 2rem;
        box-sizing: border-box;
        background-color: #ffffd9;
        p {
          margin-bottom: 0;
        }
  h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 1.4rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border: 0px none #ccc;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
::-webkit-scrollbar-thumb:active {
  background: #ccc;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.app-wrapper {
  position: relative;
  overflow: hidden;

}
.page-footer {
  clear: both;
    position: relative;
    height: 250px;
  width: 100%;
  //height: 170px;
  max-width: 1100px;
    margin: 0 auto;
    font-size: 0.85rem;
    color: #707070;
  //  position: absolute;
  //  bottom: 0;
    //left: 50%;
    //transform: translateX(-50%);
    @include media("<=desktop") {
      width: 90%
    }
}